import Vue from 'vue';
import Router from 'vue-router';
import LoginForm from '../Auth/LoginForm.vue';
import MainLayout from '../components/MainLayout.vue';
import Dashboard from '../components/Dashboard.vue';
import AlertOverview from '../components/AlertOverview.vue';
import ListFlowRuns from '../components/ListFlowruns.vue';
import Settings from '../components/Settings.vue';
import Downloads from '../components/Downloads.vue';
import store from '../store';

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  sessionStorage.setItem('redirectPath', to.path);
  next('/login');
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: MainLayout,
      children: [
        {
          path: 'environments/:envId',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'environments/:envId/alerts',
          name: 'Alerts',
          component: AlertOverview,
        },
        {
          path: 'environments/:envId/flows/:flowId',
          name: 'ListFlowRuns',
          component: ListFlowRuns,
        },
      ],
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/downloads',
      name: 'Downloads',
      component: Downloads,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginForm,
      beforeEnter: ifNotAuthenticated,
    },
  ],
});
