import axios from 'axios';
import constants from '../shared/constants';
import serviceconfig from './serviceConfig';

export default {
  async getAlerts(customerId, environmentId, flowId, userId, alertScope) {
    if (alertScope === constants.ALERT_SCOPE_FLOW) return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/alerts/${userId}`);
    if (alertScope === constants.ALERT_SCOPE_ENVIRONMENT) return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/alerts/${userId}`);
    return null;
  },

  async deleteAlert(customerId, environmentId, flowId, alertId, alertScope) {
    if (alertScope === constants.ALERT_SCOPE_FLOW) return axios.delete(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/alerts/${alertId}`);
    if (alertScope === constants.ALERT_SCOPE_ENVIRONMENT) return axios.delete(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/alerts/${alertId}`);
    return null;
  },

  async addAlert(customerId, environmentId, flowId, alertScope, body) {
    if (alertScope === constants.ALERT_SCOPE_FLOW) return axios.post(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/alerts`, body);
    if (alertScope === constants.ALERT_SCOPE_ENVIRONMENT) return axios.post(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/alerts`, body);
    return null;
  },
};
