<template>
  <div v-if="flowRuns">
    <add-alert-modal :alertScope="Constants.ALERT_SCOPE_FLOW" :onClose="modalClosed" />

    <div id="flowInfoContainer" class="mb-2" v-if="flow">
      <h4>
        {{ flow.name}}
        <span class="ms-1 me-2" v-if="flow.state">
          <font-awesome-icon v-title="$t('flow.statusOn')"  icon="play-circle" class="fa-small" v-if="flow.state === Constants.FLOW_STATE_STARTED" />
          <font-awesome-icon v-title="$t('flow.statusOff')" icon="stop-circle" class="fa-small" v-if="flow.state === Constants.FLOW_STATE_STOPPED" />
          <font-awesome-icon v-title="$t('flow.statusSuspended')" icon="times-circle" class="fa-small" v-if="flow.state === Constants.FLOW_STATE_SUSPENDED" />
        </span>
        <a
          target="_blank"
          class="copyLink ms-2"
          v-title="$t('flow.openFlow')"
          title-placement="bottom"
          :href="getFlowLink(flow.id)">
            <font-awesome-icon icon="external-link-alt" style="font-size:0.8em" />
          </a>
          <span class="refreshIcon ms-3" @click="refresh()" v-title="$t('general.update')">
            <font-awesome-icon  icon="sync-alt" class="fa-small" :class="{'fa-spin' : refreshInProgress}" />
          </span>
      </h4>
      <div>
        <small class="flowCreatorInfo">
          {{ $t("flow.createdOn") }} {{ flow.createdOn | date}}<br />
          <span v-if="flow.owners === null || flow.owners.length === 0">
            {{ $t("flow.createdBy") }}
          </span>
          <span
            v-if="flow.owners === null || flow.owners.length === 0"
            class="copyLink userLink"
            v-title="$t('flow.openUser')"
            title-placement="bottom">
              {{flow.createdByDisplayName ? flow.createdByDisplayName : $t('general.unknown')}}<br />
          </span>

          <span v-if="flow.owners !== null && flow.owners.length > 0">{{ $t('flow.owner') }}:</span>
          <span v-for="(owner, index) in flow.owners" :key="owner.id"
            class="copyLink userLink"
            v-title="$t('flow.openUser')"
            title-placement="bottom">
            {{owner.displayName ? owner.displayName : $t('general.unknown')}}
            <span class="userSeparator" v-if="index <= flow.owners.length - 3">,</span>
            <span class="userSeparator" v-if="index === flow.owners.length - 2">{{ $t('general.and') }}</span>
          </span>
          </small>
      </div>
      <div v-show="flow.description">
        <small class="flowDescription">{{flow.description}}</small>
      </div>
    </div>

      <div class="Accordion" v-if="flowRuns && alerts">
        <expander :title="$t('flow.alerts') + ' (' + alerts.length + ')'" >
          <div id="alertsContainer" class="table-responsive mb-2">
             <el-table
                lazy
                :data="alerts"
                :default-sort="{prop:'createdOn', order: 'descending'}"
                style="width:100%;">
                <div slot="empty">{{ $t("notifications.noAlertsConfigured") }}</div>
                <el-table-column
                  prop="alertType"
                  :label="$t('flow.alertType')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.alertType === Constants.ALERT_TYPE_EMAIL">
                        <font-awesome-icon icon="envelope" v-title="scope.row.alertType" />
                      </div>
                      <div v-else>
                        {{ scope.row.alertType }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                  prop="alertResultType"
                  :label="$t('flow.alertCondition')">
                    <template slot-scope="scope">
                        <div v-if="scope.row.alertResultType === Constants.FLOW_RUN_SUCCEEDED">
                        <span class="resultIcon">
                          <font-awesome-icon icon="check-circle" />
                        </span>
                      </div>
                      <div v-else>
                        <span class="resultIcon">
                          <font-awesome-icon icon="times-circle" />
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                  prop="alertTarget"
                  :label="$t('flow.alertTarget')">
                  </el-table-column>
                  <el-table-column
                    prop="createdOn"
                    sortable
                    :label="$t('flow.alertCreatedOn')">
                      <template slot-scope="scope">
                        {{ scope.row.createdOn | date }}
                      </template>
                  </el-table-column>
                  <el-table-column
                  width="80">
                  <template slot="header">
                    <span class="clickable" @click="addAlert()" v-title="$t('flow.addAlert')">
                      <font-awesome-icon icon="plus" />
                    </span>
                  </template>
                    <template slot-scope="scope">
                      <span class="clickable" @click="deleteAlert(scope.row.id)" v-title="$t('flow.deleteAlert')">
                        <font-awesome-icon icon="trash-alt" />
                      </span>
                    </template>
                  </el-table-column>
              </el-table>
            </div>
        </expander>

        <expander :title="$t('general.lastRuns')" :open="true">
          <div id="flowRunContainer" class="table-responsive">
            <el-table
              lazy
              :data="flowRuns"
              :default-sort="{prop:'startedOn', order: 'descending'}"
              :row-class-name="rowClassName"
              style="width:100%;"
              height="610">
              <div slot="empty">{{$t('notifications.noFlowRunsFound')}}</div>
              <el-table-column width="45" label="✔" v-if="errorHandledEnabled"
              :filter-multiple="false" :filters="[{text:$t('general.yes'), value: true},{text: $t('general.no'), value: false}]"
              :filter-method="filterErrorHandled">
                <template  slot-scope="scope">
                  <input
                    type="checkbox"
                    v-if="scope.row.status === Constants.FLOW_RUN_FAILED || scope.row.status === Constants.FLOW_RUN_CANCELLED"
                    :checked="scope.row.errorHandled ? true : false"
                    @change="errorHandledChanged($event, scope.row.id, scope.row.flowId, scope.row.environmentId)" />
                </template>
              </el-table-column>
              <el-table-column
                prop="startedOn"
                :label="$t('general.startedOn')"
                sortable>
                  <template slot-scope="scope">
                      {{ scope.row.startedOn | dateTime }}
                  </template>
                </el-table-column>
                <el-table-column
                prop="duration"
                :label="$t('general.duration')">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status === Constants.FLOW_RUN_RUNNING">{{$t('general.running')}}</span>
                    <span v-else>{{ scope.row.duration | duration }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                prop="status"
                :label="$t('general.result')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.status === Constants.FLOW_RUN_SUCCEEDED" class="ps-3">
                      <span class="resultIcon success">
                        <font-awesome-icon icon="check-circle" />
                      </span>
                    </div>
                    <div v-else-if="scope.row.status === Constants.FLOW_RUN_RUNNING" class="ps-3">
                      <span class="resultIcon running">
                        <font-awesome-icon icon="angle-double-right" />
                      </span>
                    </div>
                    <div v-else class="ps-3">
                      <span class="resultIcon failed" v-title="scope.row.remediation ? scope.row.remediation.errorSubject : $t('notifications.reasonUnknown')" title-placement="top-start" >
                        <font-awesome-icon icon="times-circle" />
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('general.link')"
                  width="80">
                    <template slot-scope="scope">
                      <a
                        target="_blank"
                        class="copyLink ms-1"
                        :href="getFlowRunLink(scope.row.id)">
                          <font-awesome-icon icon="external-link-alt" />
                      </a>
                    </template>
                </el-table-column>
            </el-table>

            <div class="w-100 pb-1 mt-2" v-if="flowRuns && flowRuns.length > 0">
              <div class="btn bg-purple w-100" @click="loadMoreClicked()">
                {{ $t('flow.loadMore') }}
              </div>
            </div>
          </div>
        </expander>
      </div>

  </div>
  <div class="row" v-else>
    <div class="d-flex align-items-center justify-content-center fillRemaining">
        <span class="d-none d-sm-inline text-center">
          <font-awesome-icon icon="sync" class="fa-spin fa-3x" />
        </span>
    </div>
  </div>
</template>

<script>
import {
  DELETE_FLOW_ALERT_REQUEST, GET_FLOW_ALERTS_REQUEST,
} from '../store/actions/alert';
import { GET_FLOW_RUNS_REQUEST, UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST } from '../store/actions/flow';

import { Constants } from '../shared/constants';
import AddAlertModal from './AddAlert.vue';

export default {
  name: 'ListFlowruns',
  components: {
    AddAlertModal,
  },
  created() {
    if (this.$store.getters.customerId && this.$route.params.envId && this.$route.params.flowId) {
      this.$store.dispatch(GET_FLOW_RUNS_REQUEST, {
        customerId: this.$store.getters.customerId,
        environmentId: this.$route.params.envId,
        flowId: this.$route.params.flowId,
        page: this.page,
      });

      this.loadAlerts();
    }
  },
  data() {
    return {
      refreshInProgress: false,
      page: 1,
      Constants,
      search: '',
    };
  },
  computed: {
    flow() {
      const array = this.$store.state.flow.flows;
      return array ? array.filter((flow) => flow.id === this.$route.params.flowId)[0] : array;
    },
    flowRuns() {
      return this.$store.state.flow.flowRuns;
    },
    alerts() {
      return this.$store.state.alert.alerts;
    },
    errorHandledEnabled() {
      return this.$store.getters.customerSettings?.useErrorTreatedOption;
    },
  },
  methods: {
    rowClassName({ row }) {
      // eslint-disable-next-line no-nested-ternary
      return row.status === Constants.FLOW_RUN_SUCCEEDED ? 'success' : (row.status === Constants.FLOW_RUN_RUNNING ? 'running' : 'failed');
    },
    getFlowRunLink(flowRunId) {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;

      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      // eslint-disable-next-line max-len
      return `https://flow.microsoft.com/manage/environments/${environment.name}/flows/${this.$route.params.flowId}/runs/${flowRunId}`;
    },
    getFlowLink(flowId) {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;

      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      // eslint-disable-next-line max-len
      return `https://flow.microsoft.com/manage/environments/${environment.name}/flows/${flowId}/details`;
    },
    getUserLink(userId) {
      return `https://delve.office.com/?u=${userId}`;
    },
    loadMoreClicked() {
      const beforeUpdate = this.flowRuns.length;
      this.page += 1;
      this.$store.dispatch(GET_FLOW_RUNS_REQUEST, {
        customerId: this.$store.getters.customerId,
        environmentId: this.$route.params.envId,
        flowId: this.$route.params.flowId,
        page: this.page,
      })
        .then(() => {
          if (beforeUpdate === this.flowRuns.length) {
            this.$toasted.info(this.$t('flow.noMoreFlowRunsFound'), { duration: 2400 });
          }
        });
    },
    filterErrorHandled(filterValue, item) {
      if (filterValue) return item.errorHandled === filterValue;
      return item.errorHandled === null || item.errorHandled === false;
    },
    errorHandledChanged(event, flowRunId, flowId, environmentId) {
      this.$store.dispatch(UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST,
        {
          customerId: this.$store.getters.customerId,
          environmentId,
          flowId,
          flowRunId,
          handled: event.target.checked,
        })
        .then(() => {
          this.refresh(true);
        })
        .catch(() => {
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        });
    },
    refresh(dontShowHint) {
      if (this.$store.getters.customerId && this.$route.params.envId && this.$route.params.flowId) {
        this.refreshInProgress = true;

        this.loadAlerts();

        this.$store.dispatch(GET_FLOW_RUNS_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: this.$route.params.envId,
          flowId: this.$route.params.flowId,
          page: this.page,
        })
          .then(() => {
            if (!dontShowHint) this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.refreshInProgress = false;
          });
      }
    },
    addAlert() {
      this.$modal.show('add-alert');
      this.loadAlerts();
    },
    loadAlerts() {
      if (this.$store.getters.customerId && this.$route.params.envId && this.$route.params.flowId) {
        this.$store.dispatch(GET_FLOW_ALERTS_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: this.$route.params.envId,
          flowId: this.$route.params.flowId,
          userId: this.$store.getters.customerId,
        })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.loadAlertsError'), { duration: 2400 });
          });
      }
    },
    modalClosed() {
      this.loadAlerts();
    },
    deleteAlert(alertId) {
      if (this.$store.getters.customerId && this.$route.params.envId && this.$route.params.flowId) {
        this.$store.dispatch(DELETE_FLOW_ALERT_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: this.$route.params.envId,
          flowId: this.$route.params.flowId,
          alertId,
        })
          .then(() => {
            this.loadAlerts();
            this.$toasted.success(this.$t('notifications.deleteAlertSuccess'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.deleteAlertError'), { duration: 2400 });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resultIcon{
    &.success{
        color:darkgreen;
    }

    &.failed{
        color:darkred;
    }
}

a{
  color:inherit;
}

.copyLink{
  cursor: pointer;

  &:hover {
    color:inherit;
  }
}

.userLink {
  font-weight: bold;
}
.userSeparator{
  font-weight: normal;
}

.ps{
  max-height: calc(100vh - 220px);
}
</style>
