<template>
  <modal name="send-feedback" transition="pop-out" :width="modalWidth" :focus-trap="true" :height="400" @closed="closed">
    <div class="box">
      <div class="partition" id="partition-register">
          <div class="partition-title">{{ $t("feedbackModal.modalTitle") }}</div>
          <div class="partition-form">
              <form autocomplete="false" name="sendFeedbackForm" @submit.prevent="sendFeedback()">
                  <p><small>{{ $t("feedbackModal.hintText") }}</small></p>

                  <label for="n-address">{{ $t("feedbackModal.contactAddress") }}</label>
                  <input id="n-address" type="email" :placeholder="$t('general.emailAddress')" autocomplete="false" v-model="contactAddress" />

                  <label for="n-feedback">{{ $t("feedbackModal.feedback") }}</label>
                  <textarea id="n-feedback" required name="n-feedback" cols="500" rows="10" :placeholder="$t('feedbackModal.feedbackPlaceholder')" autocomplete="false" v-model="feedback">
                  </textarea>

                  <div class="button-set text-end mt-2">
                      <button class="btn-fake" type="submit" id="save-btn">{{ $t("general.send") }}</button>
                      <div class="btn-fake" id="cancel-btn" @click="cancel" >{{ $t("general.cancel") }}</div>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { Constants } from '../shared/constants';
import feedbackService from '../services/feedbackService';

const MODAL_WIDTH = 656;

export default {
  name: 'SendFeedbackModal',
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      contactAddress: '',
      feedback: '',
      inProgress: false,
      Constants,
    };
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  methods: {
    cancel() {
      this.$modal.hide('send-feedback');
      this.alertTarget = '';
      this.resultType = '';
    },
    closed() {

    },
    sendFeedback() {
      feedbackService.sendFeedback(this.contactAddress, this.feedback)
        .then(() => { this.$toasted.success(this.$t('notifications.sendFeedbackSuccess'), { duration: 1200 }); })
        .catch(() => { this.$toasted.error(this.$t('notifications.sendFeedbackError'), { duration: 2400 }); })
        .finally(() => { this.$modal.hide('send-feedback'); });
    },
  },
};
</script>
<style lang="scss">
$background_color: #404142;

.box {
  background: white;
  overflow: hidden;
  height: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  label, small {
    display: block;
    box-sizing: border-box;
    margin-bottom: 2px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    padding: 2px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  textarea {
    resize: none;
    width:100%;
    height:110px;
    max-height: 110px;
    line-height: 1.2em;
    font-size: 12px;
  }

  small {
      font-size: 10px;
      margin: 0;
      padding:0;
      line-height:1;
  }

  input[type='text'],
  input[type='email'],
  select {
    display: block;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .btn-fake {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    min-width: 140px;
    width: 140px;
    text-align: center;
    margin-top: 8px;
    display: inline-block;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
  }

  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }

  #cancel-btn,
  #save-btn {
    margin-left: 8px;
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
