/* eslint-disable no-shadow */
import {
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_ERROR,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_ERROR,
  DELETE_FAVORITE_REQUEST,
  DELETE_FAVORITE_SUCCESS,
  DELETE_FAVORITE_ERROR,
} from '../actions/favorite';
import apiService from '../../services/favoriteService';

const state = {
  status: '',
  favorites: null,
};

const actions = {
  [GET_FAVORITES_REQUEST]: ({ commit }, userId) => new Promise((resolve, reject) => {
    if (userId) {
      commit(GET_FAVORITES_REQUEST);
      apiService.getFavorites(userId)
        .then((response) => {
          commit(GET_FAVORITES_SUCCESS, response);
          resolve(response.data);
        })
        .catch((err) => {
          commit(GET_FAVORITES_ERROR);
          reject(err);
        });
    }
  }),
  [ADD_FAVORITE_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(ADD_FAVORITE_REQUEST);
    apiService.addFavorite(data.userId, data.flowId)
      .then(() => {
        commit(ADD_FAVORITE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(ADD_FAVORITE_ERROR);
        reject(err);
      });
  }),
  [DELETE_FAVORITE_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(DELETE_FAVORITE_REQUEST);
    apiService.deleteFavorite(data.userId, data.favoriteId)
      .then(() => {
        commit(DELETE_FAVORITE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(DELETE_FAVORITE_ERROR);
        reject(err);
      });
  }),
};

const mutations = {
  [GET_FAVORITES_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FAVORITES_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.favorites = response.data;
  },
  [GET_FAVORITES_ERROR]: (state) => {
    state.status = 'error';
  },
  [ADD_FAVORITE_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [ADD_FAVORITE_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [ADD_FAVORITE_ERROR]: (state) => {
    state.status = 'error';
  },
  [DELETE_FAVORITE_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [DELETE_FAVORITE_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [DELETE_FAVORITE_ERROR]: (state) => {
    state.status = 'error';
  },
};

export default {
  state,
  actions,
  mutations,
};
