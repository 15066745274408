/* eslint-disable no-shadow */
import axios from 'axios';
import authService from '../../services/authService';
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  UPDATE_CUSTOMER_SETTINGS,
} from '../actions/auth';

const apiKeyKey = 'api-key';
const customerIdKey = 'customer-id';
const tenantIdKey = 'tenant-id';
const customerSettingsKey = 'customer-settings';

const state = {
  apiKey: sessionStorage.getItem(apiKeyKey) || '',
  customerId: sessionStorage.getItem(customerIdKey) || '',
  tenantId: sessionStorage.getItem(tenantIdKey) || '',
  customerSettings: JSON.parse(sessionStorage.getItem(customerSettingsKey)) || null,
  status: '',
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: (state) => !!state.apiKey && !!state.customerId,
  apiKey: (state) => state.apiKey,
  customerId: (state) => state.customerId,
  authStatus: (state) => state.status,
  tenantId: (state) => state.tenantId,
  customerSettings: (state) => state.customerSettings,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST);

    authService.authenticate(user.username, user.password)
      .then((resp) => {
        sessionStorage.setItem(apiKeyKey, resp.data.apiKey);
        sessionStorage.setItem(customerIdKey, resp.data.customerId);
        sessionStorage.setItem(tenantIdKey, resp.data.tenantId);
        sessionStorage.setItem(customerSettingsKey, JSON.stringify(resp.data.settings));

        axios.defaults.headers.common.ApiKey = resp.data.apiKey;

        commit(AUTH_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(AUTH_ERROR, err);
        sessionStorage.removeItem(apiKeyKey);
        sessionStorage.removeItem(customerIdKey);
        sessionStorage.removeItem(tenantIdKey);
        sessionStorage.removeItem(customerSettingsKey);
        reject(err);
      });
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    commit(AUTH_LOGOUT);
    sessionStorage.removeItem(apiKeyKey);
    sessionStorage.removeItem(customerIdKey);
    sessionStorage.removeItem(tenantIdKey);
    sessionStorage.removeItem(customerSettingsKey);
    sessionStorage.removeItem('redirectPath');
    resolve();
  }),
  [UPDATE_CUSTOMER_SETTINGS]: ({ commit }, data) => new Promise((resolve) => {
    commit(UPDATE_CUSTOMER_SETTINGS, data);
    resolve();
  }),
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';

    state.apiKey = resp.data.apiKey;
    state.customerId = resp.data.customerId;
    state.tenantId = resp.data.tenantId;
    state.customerSettings = resp.data.settings;

    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.apiKey = '';
    state.customerId = '';
    state.tenantId = '';
    state.customerSettings = null;
  },
  [UPDATE_CUSTOMER_SETTINGS]: (state, data) => {
    state.customerSettings = data;
    sessionStorage.setItem(customerSettingsKey, JSON.stringify(data));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
