import axios from 'axios';
import serviceconfig from './serviceConfig';

export default {
  async getFavorites(userId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${userId}/favorites`);
  },
  async addFavorite(userId, flowId) {
    return axios.post(`${serviceconfig.apiBase}/customers/${userId}/favorites`, { flowId });
  },
  async deleteFavorite(userId, favoriteId) {
    return axios.delete(`${serviceconfig.apiBase}/customers/${userId}/favorites/${favoriteId}`);
  },
};
