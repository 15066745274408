/* eslint-disable import/prefer-default-export */
export const Constants = Object.freeze({
  FLOW_STATE_STARTED: 'Started',
  FLOW_STATE_SUSPENDED: 'Suspended',
  FLOW_STATE_STOPPED: 'Stopped',
  FLOW_RUN_SUCCEEDED: 'Succeeded',
  FLOW_RUN_FAILED: 'Failed',
  FLOW_RUN_CANCELLED: 'Cancelled',
  FLOW_RUN_RUNNING: 'Running',
  ALERT_TYPE_EMAIL: 'EMail',
  ALERT_SCOPE_FLOW: 'Flow',
  ALERT_SCOPE_ENVIRONMENT: 'Environment',
  GROUP_TARGET_CREATOR: 'createdByDisplayName',
  GROUP_TARGET_STATE: 'state',
  GROUP_TARGET_NONE: 'None',
  INSTANCE: process.env.VUE_APP_INSTANCE,
});

export default Constants;
