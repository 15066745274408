/* eslint-disable no-shadow */
import {
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_ERROR,
  UPDATE_CUSTOMER_SETTINGS_REQUEST,
  GET_ENVIRONMENT_FLOW_RUNS_REQUEST,
  GET_ENVIRONMENT_FLOW_RUNS_SUCCESS,
} from '../actions/customer';
import apiService from '../../services/customerService';

const state = {
  status: '',
  environments: null,
  environmentFlowRuns: null,
};

const actions = {

  [GET_ENVIRONMENTS_REQUEST]: ({ commit }, customerId) => new Promise((resolve, reject) => {
    if (customerId) {
      commit(GET_ENVIRONMENTS_REQUEST);
      apiService.getEnvironments(customerId)
        .then((response) => {
          commit(GET_ENVIRONMENTS_SUCCESS, response);
          resolve(response.data);
        })
        .catch((err) => {
          commit(GET_ENVIRONMENTS_ERROR);
          reject(err);
        });
    }
  }),
  [UPDATE_CUSTOMER_SETTINGS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    if (data.customerSettings) {
      commit(UPDATE_CUSTOMER_SETTINGS_REQUEST);
      apiService.updateSettings(data.customerId, data.customerSettings)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  }),
  [GET_ENVIRONMENT_FLOW_RUNS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_ENVIRONMENT_FLOW_RUNS_REQUEST);
    apiService.getEnvironmentFlowRuns(data.customerId, data.environmentId)
      .then((response) => {
        commit(GET_ENVIRONMENT_FLOW_RUNS_SUCCESS, response.data);
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
};

const mutations = {
  [GET_ENVIRONMENTS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_ENVIRONMENTS_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.environments = response.data;
  },
  [GET_ENVIRONMENTS_ERROR]: (state) => {
    state.status = 'error';
  },
  [UPDATE_CUSTOMER_SETTINGS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_ENVIRONMENT_FLOW_RUNS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_ENVIRONMENT_FLOW_RUNS_SUCCESS]: (state, data) => {
    state.environmentFlowRuns = data;
    state.status = 'success';
  },
};

export default {
  state,
  actions,
  mutations,
};
