import axios from 'axios';

// eslint-disable-next-line max-len
const feedbackFlowUrl = 'https://prod-239.westeurope.logic.azure.com:443/workflows/54810f57ef4e4bf7b4d093f9776abf80/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OsvEDQ7UElWvu32sCxgjIsogOb1asW5yNB_lYDYyQGk';

export default {
  async sendFeedback(contactAddress, feedback) {
    return axios.post(feedbackFlowUrl, { contactAddress, feedback });
  },
};
