import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Clipboard from 'v-clipboard';
import Toasted from 'vue-toasted';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VTitle from 'v-title';
import vmodal from 'vue-js-modal';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import dateTimeFilter from './shared/dateTime-filter';
import dateFilter from './shared/date-filter';
import durationFilter from './shared/duration-filter';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'v-title/lib/element-ui';
import ExpanderComponent from './components/ExpanderComponent.vue';
import { languages, defaultLocale } from './locale/index';

Vue.config.productionTip = false;

// Plugins
Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: localStorage.getItem('userLanguage') || defaultLocale,
  fallbackLocale: 'en',
  messages,
});

Vue.use(Clipboard);

Vue.use(Toasted, { theme: 'bubble' });

Vue.use(PerfectScrollbar);

Vue.use(VTitle);

Vue.use(vmodal);

locale.use(lang);
Vue.use(ElementUI);

library.add(fas);
library.add(far);
library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Expander', ExpanderComponent);

// Filters
Vue.filter('date', dateFilter);
Vue.filter('dateTime', dateTimeFilter);
Vue.filter('duration', durationFilter);

// Mount
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
