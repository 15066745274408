export const GET_FLOW_ALERTS_REQUEST = 'GET_FLOW_ALERTS_REQUEST';
export const GET_FLOW_ALERTS_SUCCESS = 'GET_FLOW_ALERTS_SUCCESS';
export const GET_FLOW_ALERTS_ERROR = 'GET_FLOW_ALERTS_ERROR';

export const DELETE_FLOW_ALERT_REQUEST = 'DELETE_FLOW_ALERT_REQUEST';
export const DELETE_FLOW_ALERT_SUCCESS = 'DELETE_FLOW_ALERT_SUCCESS';
export const DELETE_FLOW_ALERT_ERROR = 'DELETE_FLOW_ALERT_ERROR';

export const ADD_FLOW_ALERT_REQUEST = 'ADD_FLOW_ALERT_REQUEST';
export const ADD_FLOW_ALERT_SUCCESS = 'ADD_FLOW_ALERT_SUCCESS';
export const ADD_FLOW_ALERT_ERROR = 'ADD_FLOW_ALERT_ERROR';

export const GET_ENVIRONMENT_ALERTS_REQUEST = 'GET_ENVIRONMENT_ALERTS_REQUEST';
export const GET_ENVIRONMENT_ALERTS_SUCCESS = 'GET_ENVIRONMENT_ALERTS_SUCCESS';
export const GET_ENVIRONMENT_ALERTS_ERROR = 'GET_ENVIRONMENT_ALERTS_ERROR';

export const DELETE_ENVIRONMENT_ALERT_REQUEST = 'DELETE_ENVIRONMENT_ALERT_REQUEST';
export const DELETE_ENVIRONMENT_ALERT_SUCCESS = 'DELETE_ENVIRONMENT_ALERT_SUCCESS';
export const DELETE_ENVIRONMENT_ALERT_ERROR = 'DELETE_ENVIRONMENT_ALERT_ERROR';

export const ADD_ENVIRONMENT_ALERT_REQUEST = 'ADD_ENVIRONMENT_ALERT_REQUEST';
export const ADD_ENVIRONMENT_ALERT_SUCCESS = 'ADD_ENVIRONMENT_ALERT_SUCCESS';
export const ADD_ENVIRONMENT_ALERT_ERROR = 'ADD_ENVIRONMENT_ALERT_ERROR';
