<template>
    <div class="d-md-inline ps-1 ms-1 me-2 link-hover">
            <span
                v-for="(lang, i) in availableLangs"
                :key="`lang-${i.key}`"
                @click="switchLanguage(lang.key)">
                    {{ lang.text }}
            </span>
    </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  computed: {
    availableLangs() {
      return this.langs.filter((item) => item.key !== this.$i18n.locale);
    },
  },
  data() {
    return {
      langs: [
        {
          key: 'en',
          text: 'EN',
        },
        {
          key: 'de',
          text: 'DE',
        },
      ],
    };
  },
  methods: {
    switchLanguage(lang) {
      localStorage.setItem('userLanguage', lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
