/* eslint-disable no-mixed-operators */
export default function (value) {
  if (!value) return '';

  if (value < 0) return `${0} ms`;
  if (value < 1) return `${(value * 1000).toFixed(0)} ms`;

  const sec = parseInt(value, 10);
  let dayString = '';
  const days = Math.floor(sec / 86400);
  let hours = Math.floor(sec % 86400 / 3600);
  let minutes = Math.floor(sec % 86400 % 3600 / 60);
  let seconds = Math.floor(sec % 86400 % 3600 % 60);
  if (days > 0) { dayString = `${days}d `; }
  if (hours < 10) { hours = `${hours.toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false })}`; }
  if (minutes < 10) { minutes = `${minutes.toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false })}`; }
  if (seconds < 10) { seconds = `${seconds.toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false })}`; }

  return `${dayString}${hours}:${minutes}:${seconds}`; // Return in xxd HH:MM:SS format
}
