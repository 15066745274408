<template>
  <div class="row">
    <div id="loginFormContainer" class="col d-flex align-items-center justify-content-center vh-100">
      <div class="login-form-wrapper bg-white shadow rounded">
        <div class="logo text-center">
            <img
              src="../assets/logo-dark.png"
              height="120px"
              class="d-inline-block align-top"
              alt="Logo"
            />
        </div>
        <form class="login" @submit.prevent="login">
          <label class="mt-1 mb-1">{{ $t("login.customerid") }}</label>
          <input
            required
            v-model="username"
            type="text"
            class="border"
            v-bind:class="{ 'border-danger shake bg-error': loginFailed }"
            :placeholder="$t('login.customerid')"
          />
          <label class="mt-3 mb-1">{{ $t("login.password") }}</label>
          <input
            required
            v-model="password"
            type="password"
            class="border"
            v-bind:class="{ 'border-danger shake bg-error': loginFailed }"
            :placeholder="$t('login.password')"
          />
          <hr />
          <button type="submit" class="btn bg-purple" :disabled="!(username && password) || loginInProgress">
            <span v-if="!loginInProgress">{{ $t("login.loginText") }}</span>
            <span v-show="loginInProgress"><font-awesome-icon icon="sync" class="fa-spin" /></span>
          </button>
        </form>
        <small v-if="Constants.INSTANCE === 'IOZ'" class="ms-2 mt-5">No Account? <a href="https://www.ioz.ch/angebot/flowitor-monitoring-plattform-fuer-power-automate-flows/">Contact us!</a></small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#loginFormContainer{
  background-color: #a21846;

  .login-form-wrapper {
    padding: 40px;

    .login {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    .bg-error {
      background-color: rgba(255, 0, 0, 0.1);
    }
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

<script>
import { AUTH_REQUEST } from '../store/actions/auth';
import { Constants } from '../shared/constants';

export default {
  name: 'login',
  data() {
    return {
      loginFailed: false,
      loginInProgress: false,
      username: '',
      password: '',
      Constants,
    };
  },
  methods: {
    login() {
      const { username, password } = this;
      this.loginFailed = false;
      this.loginInProgress = true;

      this.$store.dispatch(AUTH_REQUEST, { username, password })
        .then(() => {
          this.loginFailed = false;

          this.$router.push(sessionStorage.getItem('redirectPath') || '/');
        })
        .catch(() => {
          this.loginFailed = true;
        })
        .finally(() => {
          this.loginInProgress = false;
        });
    },
  },
};
</script>
