<template>
    <div id="SettingsContainer" class="mt-4">
        <div class="row mb-1">
            <div class="col-sm-12">
                <h3>{{ $t("settings.pageTitle") }}</h3>
            </div>
        </div>

        <form @submit.prevent="updateCustomerSettings">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{ $t("settings.behaviour") }}</h5>
                    <div class="form-check mt-2">
                        <input
                            class="form-check-input"
                            type="checkbox" value=""
                            id="errorTreatedCheckChecked"
                            v-model="customerSettings.useErrorTreatedOption"
                            :checked="customerSettings.useErrorTreatedOption ? true : false" />
                        <label class="form-check-label" for="errorTreatedCheckChecked">
                            {{ $t("settings.useErrorTreatedOption") }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <h5 class="card-title">{{ $t("settings.dailySummary") }}</h5>
                    <div class="form-check mt-2">
                        <input
                            class="form-check-input"
                            type="checkbox" value=""
                            id="summaryCheckChecked"
                            @change="summaryCheckBoxChecked()"
                            v-model="customerSettings.receiveDailySummaryEmail"
                            :checked="customerSettings.receiveDailySummaryEmail ? true : false" />
                        <label class="form-check-label" for="summaryCheckChecked">
                            {{ $t("settings.dailySummaryOption") }}
                        </label>
                        <div class="mt-1" v-if="dailySummaryIsChecked">
                          <input
                            required
                            id="receiveDailySummaryEmailTarget"
                            type="email"
                            v-placeholder="$t('settings.dailySummaryTargetOption')"
                            autocomplete="false"
                            v-model="customerSettings.receiveDailySummaryEmailTarget">
                          <small class="d-block mt-2">{{ $t('settings.dailySummaryOptionHint')}} </small>
                        </div>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-outline-secondary mt-3" :disabled="updateInProgress">{{$t('general.save')}}</button>
        </form>

    </div>
</template>

<script>
import {
  UPDATE_CUSTOMER_SETTINGS_REQUEST,
} from '../store/actions/customer';
import {
  UPDATE_CUSTOMER_SETTINGS,
} from '../store/actions/auth';

export default {
  name: 'Settings',
  data() {
    return {
      updateInProgress: false,
      dailySummaryIsChecked: false,
    };
  },
  computed: {
    customerSettings() {
      return { ...this.$store.getters.customerSettings } || {
        useErrorTreatedOption: false,
        receiveDailySummaryEmail: false,
      };
    },
  },
  mounted() {
    this.dailySummaryIsChecked = this.customerSettings.receiveDailySummaryEmail;
  },
  methods: {
    summaryCheckBoxChecked() {
      this.dailySummaryIsChecked = !this.dailySummaryIsChecked;
    },
    updateCustomerSettings() {
      this.updateInProgress = true;

      this.$store
        .dispatch(UPDATE_CUSTOMER_SETTINGS_REQUEST, { customerId: this.$store.getters.customerId, customerSettings: this.customerSettings })
        .then((response) => {
          this.$toasted.success('Gespeichert', { duration: 2400 });
          this.$store
            .dispatch(UPDATE_CUSTOMER_SETTINGS, response);
        }).catch((err) => {
          console.log(err);
          this.$toasted.error('Oh oh! Etwas ist schiefgelaufen', { duration: 2400 });
        })
        .finally(() => {
          this.updateInProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
