import axios from 'axios';
import serviceconfig from './serviceConfig';

export default {
  async getDashboard(customerId, environmentId, onlyFailedRuns) {
    let url = `${serviceconfig.apiBase}/dashboard/${customerId}/${environmentId}`;
    if (onlyFailedRuns) {
      url = `${url}?onlyFailed=true`;
    }

    return axios.get(url);
  },
};
