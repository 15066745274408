import axios from 'axios';
import serviceconfig from './serviceConfig';

export default {
  async getEnvironments(customerId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments`);
  },
  async updateSettings(customerId, customerSettings) {
    return axios.post(`${serviceconfig.apiBase}/customers/${customerId}/settings`, customerSettings);
  },
  async getEnvironmentFlowRuns(customerId, environmentId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flowruns`);
  },
};
