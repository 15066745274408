<template>
    <div class="row" v-if="$route.params.envId">
      <aside class="col-xl-2 col-lg-3 col-md-4 col-sm-12 p-2 text-light shadow">
         <perfect-scrollbar>
          <div class="dashboard-home d-inline" @click="onHomeSelected()">
            {{ $t("general.dashboard") }}
          </div>
          <span class="refreshIcon ms-2 float-end pe-3" @click="refreshFlows()" v-title="$t('general.updateFlows')">
            <font-awesome-icon icon="sync-alt" class="fa-small" :class="{'fa-spin' : flowRefreshInProgress}" />
          </span>
          <div class="mb-2 mt-3" style="display:flow-root;">
              <span class="flow-search">
                <input type="search" v-model="search" class="form-control form-control-sm" :placeholder="$t('general.searchPlaceholder')"/>
              </span>
          </div>
          <div v-if="favedFlows && favedFlows.length > 0" class="mt-3 mb-2">
            <div class="flowContainerHeading">{{ $t('general.favorites') }}</div>
            <ul id="favedFlowContainer">
                <li v-for="flow in favedFlows"
                :key="flow.id"
                :class="{'selected': flowId == flow.id}">
                  <span class="d-flex">
                    <div class="flex-grow-1 flowListName" @click="onFlowSelected(flow.id)">
                      {{flow.name}}
                    </div>
                    <div class="pe-2 favContainer pt-1" @click="toggleFaveFlow(flow.id);"
                      @mouseover="favHover = true"
                      @mouseleave="favHover = false">
                      <font-awesome-icon v-if="!favHover && !flowIsFaved(flow.id)" :icon="['far', 'star']" class="fa-small"  />
                      <font-awesome-icon v-else icon="star" class="fa-small pe-auto" />
                    </div>
                  </span>
                </li>
              </ul>
          </div>
          <hr />
          <div class="flow-select mt-2">
            <div class="flowContainerHeading">
              {{ $t('general.flows') }}
              <span class="float-end">
                <font-awesome-icon icon="user" class="pe-auto grouping-icon" @click="onGroupingClicked(Constants.GROUP_TARGET_CREATOR)" v-title="$t('grouping.groupByUser')" />
                <font-awesome-icon icon="info-circle" class="pe-auto grouping-icon" @click="onGroupingClicked(Constants.GROUP_TARGET_STATE)" v-title="$t('grouping.groupByState')" />
                <font-awesome-icon icon="times-circle" class="pe-auto grouping-icon" @click="onGroupingClicked(Constants.GROUP_TARGET_NONE)" v-title="$t('grouping.noGrouping')" />
              </span>
            </div>
            <ul class="flowContainer" v-if="flowGroupKey == Constants.GROUP_TARGET_NONE">
              <li v-for="flow in filteredFlows"
              :key="flow.id"
              :class="{'selected': flowId == flow.id}"
              >
                <span class="d-flex">
                  <div class="flex-grow-1 flowListName" @click="onFlowSelected(flow.id)">
                    {{flow.name}}
                  </div>
                  <div class="pe-2 favContainer pt-1" @click="toggleFaveFlow(flow.id)" :class="{'faved': flowIsFaved(flow.id)}"
                    @mouseover="favHover = true"
                    @mouseleave="favHover = false">
                    <font-awesome-icon v-if="!favHover && !flowIsFaved(flow.id)" :icon="['far', 'star']" class="fa-small" />
                    <font-awesome-icon v-else icon="star" class="fa-small pe-auto" />
                  </div>
                </span>
              </li>
              <div v-if="flows && flows.length <= 0">{{ $t("notifications.noFlowsFound") }}</div>
            </ul>
            <ul id="flowRootContainer" v-else>
              <li class="flowGroupContainer" v-for="(value, name) in groupedFlows"
              :key="name"
              :class="viewState.includes(name) ? 'expanded': 'hidden'"
              >
                <div class="flowGroupName" @click="toggleGroupViewState(name)">{{ name }}
                  <font-awesome-icon class="float-end showHideIcon" icon="chevron-down" v-if="!viewState.includes(name)" />
                  <font-awesome-icon class="float-end showHideIcon" icon="chevron-up" v-else />
                </div>
                <ul class="flowContainer">
                  <li v-for="flow in value"
                  :key="flow.id"
                  :class="{'selected': flowId == flow.id}"
                  >
                    <span class="d-flex">
                      <div class="flex-grow-1 flowListName" @click="onFlowSelected(flow.id)">
                        {{flow.name}}
                      </div>
                      <div class="pe-2 favContainer pt-1" @click="toggleFaveFlow(flow.id)" :class="{'faved': flowIsFaved(flow.id)}"
                        @mouseover="favHover = true"
                        @mouseleave="favHover = false">
                        <font-awesome-icon v-if="!favHover && !flowIsFaved(flow.id)" :icon="['far', 'star']" class="fa-small" />
                        <font-awesome-icon v-else icon="star" class="fa-small pe-auto" />
                      </div>
                    </span>
                  </li>
                </ul>
              </li>
              <div v-if="flows && flows.length <= 0">{{ $t("notifications.noFlowsFound") }}</div>
            </ul>
          </div>
        </perfect-scrollbar>
      </aside>

      <main ref="FlowDisplay" class="col-xl-10 col-lg-9 col-md-8 col-sm-12 pt-3">
        <router-view :key="$route.fullPath"></router-view>
      </main>

    </div>
    <div class="row text-purple" v-else>
      <div class="pt-5 mt-5 d-flex align-items-center justify-content-center">
        <span class="d-none d-sm-inline text-center">
          <font-awesome-icon  icon="magic" class="fa-5x" />
          <div class="bigText mt-3">
            {{ $t("general.selectEnvironment") }}
          </div>
        </span>
        <span class="d-block d-sm-none text-center">
          <font-awesome-icon icon="magic" class="fa-3x" />
          <div class="mt-3">
            {{ $t("general.selectEnvironment") }}
          </div>
        </span>
      </div>
      <div class="pt-2 mt-2 d-flex align-items-center justify-content-center">
        <select
          name="EnvironmentSelect"
          title="EnvironmentSelect"
          class="form-select d-md-inline mt-md-0 w-25"
          @change="onEnviromentChange($event)"
          v-model="environmentId"
        >
        <option :value="undefined" disabled hidden>{{ $t("general.selectPlaceholder") }}</option>
          <option
            v-for="environment in environments"
            :key="environment.id"
            :value="environment.id"
          >
            {{ environment.displayName }}
          </option>
        </select>
      </div>
    </div>
</template>

<script>
import { GET_ENVIRONMENTS_REQUEST } from '../store/actions/customer';
import { ADD_FAVORITE_REQUEST, GET_FAVORITES_REQUEST, DELETE_FAVORITE_REQUEST } from '../store/actions/favorite';
import { GET_FLOWS_REQUEST, CLEAR_FLOWS_REQUEST } from '../store/actions/flow';
import {
  CLEAR_DASHBOARD_REQUEST,
  GET_DASHBOARD_REQUEST,
} from '../store/actions/dashboard';
import { Constants } from '../shared/constants';

export default {
  name: 'MainLayout',
  created() {
    this.$store.dispatch(
      GET_ENVIRONMENTS_REQUEST,
      this.$store.getters.customerId,
    );
    if (this.$store.getters.customerId && this.$route.params.envId) {
      this.$store.dispatch(GET_FLOWS_REQUEST,
        {
          customerId: this.$store.getters.customerId,
          environmentId: this.$route.params.envId,
        }).then(() => {
        this.$store.dispatch(GET_FAVORITES_REQUEST, this.$store.getters.customerId).catch(() => {
          this.$toasted.error(this.$t('notifications.loadFavoritesError'), { duration: 2400 });
        });
      });
    }
  },
  data() {
    return {
      search: '',
      flowRefreshInProgress: '',
      Constants,
      favHover: false,
      selectedFlowId: '',
      viewState: [],
      flowGroupKey: Constants.GROUP_TARGET_NONE,
    };
  },
  computed: {
    flows() {
      const array = this.$store.state.flow.flows;
      if (array == null) return array;

      // eslint-disable-next-line no-nested-ternary
      const sorted = array.slice().sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0)));
      return sorted;
    },
    filteredFlows() {
      return this.flows ? this.flows.filter((flow) => flow.name.toLowerCase().includes(this.search.toLowerCase())) : this.flows;
    },
    favedFlows() {
      const { favorites } = this.$store.state.favorite;
      if (!favorites || !this.filteredFlows) return this.filteredFlows;
      return this.filteredFlows.filter((x) => favorites.some((y) => y.flowId === x.id));
    },
    environments() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      const sorted = array
        .slice()
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0));
      return sorted;
    },
    groupedFlows() {
      const groupkey = this.flowGroupKey;
      const grouped = this.filteredFlows
        // eslint-disable-next-line no-nested-ternary
        .reduce((hash, obj) => {
          if (obj[groupkey] === undefined) return hash;
          return Object.assign(hash, { [obj[groupkey] || 'Unknown']: (hash[obj[groupkey]] || []).concat(obj) });
        }, {});

      const ordered = Object.keys(grouped).sort().reduce(
        (obj, key) => {
          obj[key] = grouped[key];
          return obj;
        },
        {},
      );

      return ordered;
    },
    environmentId: {
      get() {
        return this.$route.params.envId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
    environmentName() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      return array.filter((env) => env.id === this.$route.params.envId)[0].displayName;
    },
    flowId: {
      get() {
        return this.selectedFlowId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
  },
  methods: {
    onEnviromentChange(event) {
      if (event.target.value !== '') {
        this.$store.dispatch(CLEAR_FLOWS_REQUEST);
        this.$store.dispatch(GET_FLOWS_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: event.target.value,
        }).then(() => {
          this.$store.dispatch(GET_FAVORITES_REQUEST, this.$store.getters.customerId).catch(() => {
            this.$toasted.error(this.$t('notifications.loadFavoritesError'), { duration: 2400 });
          });
        });
        this.$store.dispatch(CLEAR_DASHBOARD_REQUEST);
        this.$store.dispatch(GET_DASHBOARD_REQUEST, {
          customerId: this.$store.getters.customerId,
          environmentId: event.target.value,
        })
          .then(() => {
            this.$router.push({ name: 'Dashboard', params: { envId: event.target.value } });
          });
      }
    },
    refreshFlows() {
      if (!this.flowRefreshInProgress) {
        this.flowRefreshInProgress = true;

        this.$store.dispatch(GET_FLOWS_REQUEST,
          {
            customerId: this.$store.getters.customerId,
            environmentId: this.$route.params.envId,
          })
          .then(() => {
            this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.flowRefreshInProgress = false;
          });
      }
    },
    onFlowSelected(flowId) {
      this.selectedFlowId = flowId;
      this.$router.push({ name: 'ListFlowRuns', params: { envId: this.$route.params.envId, flowId } }).catch(() => {});
      window.setTimeout(() => { this.scrollToElement(); }, 400);
    },
    flowIsFaved(flowId) {
      if (!this.favedFlows) return false;

      return this.favedFlows.some((x) => x.id === flowId);
    },
    toggleFaveFlow(flowId) {
      if (this.flowIsFaved(flowId)) {
        this.unFaveFlow(flowId);
      } else {
        this.faveFlow(flowId);
      }
    },
    faveFlow(flowId) {
      this.$store.dispatch(ADD_FAVORITE_REQUEST,
        {
          userId: this.$store.getters.customerId,
          flowId,
        })
        .then(() => {
          this.$store.dispatch(GET_FAVORITES_REQUEST, this.$store.getters.customerId).catch(() => {
            this.$toasted.error(this.$t('notifications.loadFavoritesError'), { duration: 2400 });
          });
        })
        .catch(() => {
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        });
    },
    unFaveFlow(flowId) {
      const favorite = this.$store.state.favorite.favorites.find((x) => x.flowId === flowId);
      this.$store.dispatch(DELETE_FAVORITE_REQUEST,
        {
          userId: this.$store.getters.customerId,
          favoriteId: favorite.id,
        })
        .then(() => {
          this.$store.dispatch(GET_FAVORITES_REQUEST, this.$store.getters.customerId).catch(() => {
            this.$toasted.error(this.$t('notifications.loadFavoritesError'), { duration: 2400 });
          });
        })
        .catch(() => {
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        });
    },
    scrollToElement() {
      const el = this.$refs.FlowDisplay;
      if (el && window.innerWidth < 992) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onHomeSelected() {
      this.selectedFlowId = null;
      this.$router.push({ name: 'Dashboard', params: { envId: this.$route.params.envId } }).catch(() => {});
      window.setTimeout(() => { this.scrollToElement(); }, 400);
    },
    onGroupingClicked(grouping) {
      this.flowGroupKey = grouping;
    },
    toggleGroupViewState(key) {
      if (this.viewState.includes(key)) this.viewState.pop(key);
      else this.viewState.push(key);
    },
  },
};
</script>

<style lang="scss">
.el-table{
   .el-table__cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .el-table__row {
      &.success {
          background-color: rgba($color: #46a218, $alpha: 0.1);
      }

      &.running {
          background-color:rgba($color: #116676, $alpha: 0.1);
      }

      &.failed {
          background-color:rgba($color: #761133, $alpha: 0.1);
      }
  }
}
</style>

<style lang="scss" scoped>
h1 {
  margin: 50px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  margin:0;
}
li {
  display: inline-block;
  margin: 0;
}

aside {
  background-color: #a21846;
  z-index: 1;
}

main {
  max-height: calc(100vh - 95px);
  overflow-y: auto;
}

.ps{
  height: calc(100vh - 108px);
}

.flowGroupContainer .flowContainer{
  display: none;
}

.flowContainer,
#favedFlowContainer{
  li {
    cursor: pointer;
    overflow-wrap: break-word;
    padding: 6px 6px;
    border-bottom: 1px solid #FFF;
    width: 100%;

    &.selected{
      background-color: #ce1f5a;
    }
    &:hover{
      background: #5f0e29;
      color:whitesmoke;

      & .favContainer svg{
        display: block;
      }
    }

    & .flowListName {
      word-break: break-word;
    }
  }
}

.flowContainerHeading{
  font-weight: 500;
  padding-right: 10px;
}

.flowGroupContainer {
  display: block;
}

#favedFlowContainer{
  padding: 6px 0;
}

.favContainer{
  min-width: 26px;
  & svg{
    display: none;
  }

  color: gold;
}

.flowContainer .faved svg{
  display: block;
}

.flowGroupContainer{
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .showHideIcon{
    padding: 6px 6px 0 0;
    min-width: 26px;
  }

  ul {
    border: 1px solid #5f0e29;//#5f0e29;
    border-top: none;
    border-bottom: none;
  }

  &.expanded ul{
    display: block;
  }
  &.hidden ul{
    display: none;
  }
}

.flowGroupName{
  width:100%;
  display: block;
  background-color:#b81b50;
  font-size: 1.1em;
  margin-top:12px;
  padding:2px 0 2px 5px;
  border: 1px solid #5f0e29 !important;
}

.grouping-icon {
  padding: 0 3px;
  min-width:25px;
  cursor: pointer;
}

.dashboard-home{
  cursor: pointer;
}

.copyLink{
  cursor: pointer;
}

.resultIcon{
    &.success{
        color:darkgreen;
    }

    &.failed{
        color:darkred;
    }
}

.bigText {
  font-size: 3em;
}

.smallerText{
  font-size: 0.8em;
}
</style>
