export const CLEAR_FLOWS_REQUEST = 'CLEAR_FLOWS_REQUEST';
export const CLEAR_FLOW_RUNS_REQUEST = 'CLEAR_FLOW_RUNS_REQUEST';

export const GET_FLOWS_REQUEST = 'GET_FLOWS_REQUEST';
export const GET_FLOWS_SUCCESS = 'GET_FLOWS_SUCCESS';
export const GET_FLOWS_ERROR = 'GET_FLOWS_ERROR';

export const GET_FLOW_REQUEST = 'GET_FLOW_REQUEST';
export const GET_FLOW_SUCCESS = 'GET_FLOW_SUCCESS';
export const GET_FLOW_ERROR = 'GET_FLOW_ERROR';

export const GET_FLOW_RUNS_REQUEST = 'GET_FLOW_RUNS_REQUEST';
export const GET_FLOW_RUNS_SUCCESS = 'GET_FLOW_RUNS_SUCCESS';
export const GET_FLOW_RUNS_ERROR = 'GET_FLOW_RUNS_ERROR';

export const GET_FLOW_RUN_REQUEST = 'GET_FLOW_RUN_REQUEST';
export const GET_FLOW_RUN_SUCCESS = 'GET_FLOW_RUN_SUCCESS';
export const GET_FLOW_RUN_ERROR = 'GET_FLOW_RUN_ERROR';

export const UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST = 'UPDATE_FLOW_RUN_ERRORHANDLED_REQUEST';
